<template>
  <div>
    <div>
      <layout-breadcrumb :list="[{'name':'班级列表'},{'name':'识别列表'},{'name':className+'扫描结果'}]"></layout-breadcrumb>
    </div>
    <div style="display: flex;justify-content: space-between;position: fixed;z-index: 10;">
      <Card style="width: 270px;min-width: 270px;">
        <p slot="title">
          <Icon type="md-list" size="20"/>
          学生列表
        </p>
        <div style="min-height: 70px">
          <Spin fix v-if="studentLoading">
            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
            <div>加载中...</div>
          </Spin>
          <Tag style="cursor: pointer;" :style="{background: searchStudent == item.nickName ? '#19BE66' : ''}" size="large" :color="item.isOk ? 'primary' : 'default'" v-for="(item,index) in studentList"
               :key="index" @click.native="getUserInfo(item)">
            <span v-text="item.nickName"></span>
          </Tag>
          <div style="display: flex;margin-top: 100px;">
            <div style="margin-right: 10px;font-weight: bold;line-height: 35px;">
              选择学生
            </div>
            <Select v-model="searchStudent" clearable filterable style="width:120px">
              <Option value="" :key="0">全部</Option>
              <Option v-for="item in studentList" :value="item.nickName" :key="item.nickName">{{ item.nickName }}</Option>
            </Select>
          </div>
        </div>
      </Card>
      <Card style="min-width: 500px;margin-left: 10px;">
        <div slot="title">
          <Tag color="blue"><h2>{{className}}</h2></Tag>
        {{'扫描结果('+homeworkNumber+')'}}
        </div>
        <div slot="extra">
          <Button type="primary" icon="md-create" @click="modalStatus = true">编辑</Button>
        </div>
        <div style="height: 80vh;overflow: scroll;padding: 5px;">
          <template v-if="searchStudent">
            <div v-for="(item,index) in pageList" :key="index">
              <template v-if="searchStudent == item.createUserName">
                <div style="display: flex">
                  <div @click="goNext(item)" style="width: 200px;" class="col-box">
                    <div style="width: 100%;height: 200px;">
                      <img style="width: 100%" :src="item.originalImg">
                    </div>
                    <div>
                      <div style="padding: 5px;font-weight: bolder;font-size: 16px;" class="ellipsis">{{ item.createUserName }}</div>
                      <div style="display: flex;justify-content: space-between;padding: 0 5px 5px 5px">
                        <div>
                          <Tag color="primary">得分：{{ item.score }}</Tag>
                        </div>
                        <div>
                          <Tag color="success">识别率：{{ item.recognitionRate }}</Tag>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style="padding: 0 5px 5px 5px">
                        <Tag color="default"><Icon type="md-time" size="16"/> {{ item.createInDate }}</Tag>
                      </div>
                    </div>
                  </div>
                  <Button style="margin-left: 20px;margin-top: 100px"
                          type="warning" icon="md-close" @click="searchStudent = ''">取消选中学生</Button>
                </div>
              </template>
            </div>
          </template>
          <Row :gutter="16" v-else>
            <Col :xs="24" :sm="8" :md="8" :lg="6" v-for="(item,index) in pageList" :key="index">
              <div @click="goNext(item)" class="col-box">
                <div style="width: 100%;height: 200px;">
                  <img style="width: 100%" :src="item.originalImg">
                </div>
                <div>
                  <div style="padding: 5px;font-weight: bolder;font-size: 16px;" class="ellipsis">{{ item.createUserName }}</div>
                  <div style="display: flex;justify-content: space-between;padding: 0 5px 5px 5px">
                    <div>
                      <Tag color="primary">得分：{{ item.score }}</Tag>
                    </div>
                    <div>
                      <Tag color="success">识别率：{{ item.recognitionRate }}</Tag>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="padding: 0 5px 5px 5px">
                    <Tag color="default"><Icon type="md-time" size="16"/> {{ item.createInDate }}</Tag>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div style="display: flex;justify-content: center;margin-top: 100px" @click="queryPageList">
            <Button type="primary">加载更多</Button>
          </div>
        </div>
      </Card>
    </div>
    <Modal title="编辑识别" v-model="modalStatus" :mask-closable="false" footer-hide>
        <div>
          <Form ref="ajaxForm" :model="ajaxForm" :rules="ruleValidate" :label-width="80">
            <FormItem label="选择学生" prop="studentId">
              <Select v-model="ajaxForm.studentId" clearable filterable style="width:100%" placeholder="搜索学生">
                <Option v-for="item in studentAllList" :value="item.studentId" :key="item.studentId">{{ item.nickName }}</Option>
              </Select>
            </FormItem>
            <FormItem label="上传图片" prop="file">
              <layout-upload :imgNumber="1" :auto-upload="false" @ok="setFile"></layout-upload>
            </FormItem>
            <FormItem>
              <Button type="primary" :loading="loadingForm" @click="submit">提交</Button>
            </FormItem>
          </Form>
        </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "detail-list",
  data() {
    return {
      homeworkNumber: '',
      className: '',
      searchStudent: '',
      studentLoading: true,
      loadingForm: false,
      modalStatus: false,
      studentList:[],
      studentAllList:[],
      pageList: [],
      classId: 0,
      ajaxForm: {
        studentId: '',
        homeworkNumber: '',
        file: ''
      },
      ruleValidate: {
        studentId: [
          { required: true, type: 'number', message: '请选择学生', trigger: 'change' }
        ],
        file: [
          { required: true, message: '请选择图片'}
        ]
      },
      pageData: {
        pageNo: 1,
        pageSize: 60,
        total: 0
      },
    }
  },
  created() {
    let query = this.$routeQuery() || false
    if (query) {
      this.classId = query.classId
      this.className = query.name
      this.homeworkNumber = query.homeworkNumber
      this.queryPageList();
    }
  },
  mounted() {
  },
  methods: {
    setFile(item){
      if(item.file){
        this.ajaxForm.file = item.file
      }
    },
    getUserInfo(data){
      let nickName = data.nickName
      this.searchStudent = nickName
    },
    submit(){
      let ajaxForm = this.ajaxForm
      ajaxForm.homeworkNumber = this.homeworkNumber
      this.$refs['ajaxForm'].validate((valid) => {
        if (valid) {
          this.loadingForm = true
          this.$http.scanTeacherResult(ajaxForm).then((res)=>{
            this.loadingForm = false
            if (res.status == 1) {
              this.modalStatus = false
              this.$Message.success('提交成功!');
              location.reload()
            }else{
              this.$Message.error(res.msg || '处理失败');
            }
          })
        } else {
          this.$Message.error('表单验证失败!');
        }
      })
    },
    queryPageList() {
      this.$http.queryHomeworkInfo(
          this.pageData.pageNo,
          this.pageData.pageSize,
          this.homeworkNumber
      ).then(res => {
        this.queryStudentList();
        if (res.status == 1) {
          this.pageList.push(...res.data.records);
          this.pageData.total = res.data.total;
          this.pageData.pageNo++;
        }
      });
    },
    // 查询学生列表
    queryStudentList() {
      this.$http.queryStudentList(
          1,
          100,
          '',
          '',
          this.classId
      ).then(res => {
        this.studentLoading = false
        if (res.status == 1) {
          let list = this.pageList
          let studentList = res.data.records
          this.studentAllList = studentList
          let studentNewList = studentList.map((item)=>{
            let isOk = 0
            try {
              list.forEach((items)=>{
                if(items.createUserName == item.nickName){
                  isOk = 1
                  throw new Error("ok");
                }
              })
            }catch (e) {
              // console.log(e)
            }
            return {
              nickName: item.nickName,
              isOk: isOk
            }
          })
          this.studentList = studentNewList.sort(this.compare('isOk'))
        }
      });
    },
    changePageNo(val) {
      this.pageData.pageNo = val;
      this.queryPageList();
    },
    compare(arg) {
      return function(a, b) {
        return b[arg] - a[arg];
      }
    },
    goNext(item) {
      this.$jump('/detail-single', {sysNo: item.sysNo, className: this.className,  rowInfo: item})
    }
  }
}
</script>

<style scoped lang="less">
.col-box {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 4px #999;
  min-width: 175px;
  background-color: #ffffff;

  &:hover {
    box-shadow: 0 0 4px #666;
  }
}
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
</style>